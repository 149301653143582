@import url('https://rsms.me/inter/inter.css');

@font-face {
    font-family: 'Ayer Poster';
    src: local('Ayer Poster Medium'), local('Ayer-Poster-Medium'), url('/public/AyerPoster-Medium.woff2') format('woff2'), url('/public/AyerPoster-Medium.woff') format('woff'),
    url('/public/AyerPoster-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background: #ffd863;
}

a {
    color: black;
}


.text_stroke_only {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #827045;
}

.text_stroke_black {
    background: -webkit-linear-gradient(top, #D9D9D9 5%, #827045 53%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;
    color: #D5CCB6;
}

.text_stroke_black_text {
    color: rgba(255, 255, 255, 0.7);
}

.text_stroke_strawberry_text {
    background: -webkit-linear-gradient(top, #AEAEAE 5%, #827045 53%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    color: #D5CCB6;
}

.text_stroke_strawberry_text_berry {
    color: rgba(130, 112, 69, 0.05);
}

.text_stroke_strawberry_text_light {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #EDD7A3;
}

.text_stroke_red {
    background: -webkit-linear-gradient(top, #D63317 5%, #D63317 53%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;
    color: white;
}


.strawberry_mask {
    -webkit-mask-image: url('./assets/images/Home/strawberry_middle.png');
    mask-image: url('./assets/images/Home/strawberry_middle.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: 26.5% 44%;
    mask-size: 25.7%;
}

.menu_voice {
    writing-mode: vertical-lr;
}

.second_box {
    position: relative;
    background: #3c31dd;
    width: 100%;
    height: 100%;
}

.curve {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    width: 150%;
    height: 100%;
    top: -5%;
    left: -60%;
    background-color: hsl(216, 21%, 16%);
    clip-path: polygon(0% 79%, 20% 86%, 9% 86%, 24% 87%, 42% 83%, 59% 73%, 77% 58%, 94% 41%, 98% 27%, 99% 15%, 99% 4%, 0% 0%);
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


input[type='range'] {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #9BB0C1;
    border-radius: 5px;
    width: 100%;
    height: 2px;
    outline: 0;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #9BB0C1;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:active {
    transform: scale(1.5);
}
